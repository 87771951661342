/* Container styles */
.stats-section {
    padding: 1rem 0; 
    text-align: center; 
  }
  
  .stats-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 0 10px;
  }
  
  @media (min-width: 768px) {
    .stats-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .stats-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* Card styles */
  .stat-card {
    border: var(--border-color);
    box-shadow:  var(--box-shadow); 
   
  }
 
  .stat-number {

    margin: 0;
    font-weight: bold;
  }
  
  .serviceSection {
    padding: 50px 20px;
    text-align: center;
  }
  
    .serviceText {
      margin-bottom: 40px;
    }
    .serviceText  h2 {
        
        font-weight: bold;
      }
  
     
  
      .serviceText   p {
       
        max-width: 750px;
      }
    
  


  