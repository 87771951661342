/* Benefits Section */
.life-at-vijprat >.banner{
  height: 80vh;
    position: relative;
    background: url(/public/assets/team.webp) no-repeat center center fixed;
    background-size: cover;
    background-position: center;
}
.life-at-vijprat >.banner-text{
  position: absolute;
  top: 0;
  left:0;
  text-align: center;
}
 .banner-text h1,  .banner-text p{
 
  color: white;
}
.benefits {
    padding: 50px;
    text-align: center;
  }
  
  .benefits h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .benefit-card {
  
    padding: 30px;
    border-radius: 8px;
    box-shadow:  var(--box-shadow);
    transition: background-color 0.3s ease, opacity 0.3s ease;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  
  
  .benefit-card .icon {
    font-size: 80px; /* Increased icon size */
    margin-bottom: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .benefit-card:hover .icon {
    /* opacity: 0;
    visibility: hidden; */
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .benefit-card:hover .description {
    opacity: 1;
    visibility: visible;
  }
  
  .benefit-card .description {
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .benefit-card h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .benefits-grid {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media (max-width: 768px) {
    .benefits-grid {
      grid-template-columns: repeat(1, 1fr); 
    }
  }
  