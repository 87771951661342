.footerTop {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
}

.footerImage {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
}
.heading{

  
  text-align: center;
}
