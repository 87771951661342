/* Main Service */
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  animation: slideIn 1.5s ease-in-out;
}
.swiperSection{
width: 90%;
margin: auto;
align-items: center;
text-align:justify;
}
.imageContainer {
  width: 10rem !important;
  height: 10rem !important;
  
  display: flex;
  align-items: center;
  margin: auto;
}
.swiperCaption{
  margin: auto;
  text-align: center;
}
.imageContainer img {
  width: 100%; 
  height: 100%;
  object-fit: cover;
   
  transition: transform 0.4s ease-in-out;
}

.service-card {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow:  var(--box-shadow);
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1s ease-in-out;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-image img {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}

.service-info {
  padding: 20px;
  text-align: center;
}

.service-info h3 {
  margin-bottom: 10px;
}

.service-info p {
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .detailsContainer {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .detailsSection h2 {
    font-size: 2rem;
  }
  .detailsContainer {
    gap: 20px;
  }

  
}
