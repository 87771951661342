/* Chat Container */
.chat-container {
  width: 400px;
  max-height: 600px;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

/* Chat Window */
.chat-window {
  flex-grow: 1;
  padding: 10px;
  overflow-y: scroll;
  background-color: #fff;
  border-bottom: 2px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Message Styles */
.message {
  padding: 8px 12px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
}

.user-message {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.bot-message {
  background-color: #f1f1f1;
  color: black;
  align-self: flex-start;
}

/* Chat Input Section */
.chat-input {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-top: 2px solid #ccc;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 16px;
}

.chat-input button:hover {
  background-color: #0056b3;
}
