
.swiperSlide {
  position: relative;
}






/* Services Section */
.servicesSection {
  padding: 2rem;
  text-align: center;
}

/* Container for the cards */
.servicesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;
}

/* Individual Service Cards */
.serviceCard {

  border-radius: 10px;
  padding: .5rem;
  box-shadow: var(--box-shadow);
  width: calc(33% - 1rem);
 
  box-sizing: border-box;


}

button {

  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.cloudServiceIcons {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  margin-top: 15px;
}

.cloudServiceIcon {
  display: flex;
  align-items: end;
  margin: 5px 0;
}

.cloudIconLink{
  color:var(--link-color);
}





/* Responsive Design */
@media (max-width: 768px) {
  .serviceCard {
    width: 100%;
    /* Full width on smaller screens */
  }
}


/* Fixed Background Section */
.fixedBackgroundSection {
  position: relative;
  background: url('/public/assets/life.jpg') no-repeat center center fixed;
  background-size: cover;
  padding: 4rem 2rem;
  text-align: center;
  height: 60vh;
  color: white;
}
.fixedBackgroundSection h2{
  color: white;
}


.btnPrimary,
.btnSecondary {
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 0.5rem;
}



/* Customer Section */
.customerSection {
  padding: 2rem;
  text-align: center;
}

.customerLogos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.customerLogo {
  margin: 0.5rem;
}

.customerImage {
  width: 150px;
  height: auto;
}


/* Testimonials.module.css */
.testimonialsSection {
  text-align: center;
 width: 90%;
 margin: auto;
 
}
.testimonialsSwiper {
  display: flex;
  align-items: stretch; /* Make sure all Swiper slides have equal height */
}

.testimonialCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  height: max-content; /* Ensure the height is 100% */
  box-shadow: var(--box-shadow);
  margin: auto;
}


/* .testimonialCard {
  display: grid;
  
  text-align: center;
  

  padding: 1rem;
 
  width: 100%;
 
} */
.testimonialsSwiper .swiper-wrapper{
  padding: 1rem 0;
}


/* .testimonialContent {
  padding: 0 2px;
} */
.testimonialsSwiper .mySwiper {
  width: 100%;
  height: 100%;
  overflow: hidden; 
  align-self: center;
}

.testimonialsSwiper .swiper-slide img {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin: auto;
  object-fit: cover;
  margin-bottom: 0.5rem;
  object-fit: cover; /* Ensure the image covers the slide without shrinking */
}
.testimonialContent p {
  font-style: italic;
 
 
  /* margin-bottom: 0.5rem; */
}

.testimonialContent h4 {
  /* margin-top: 0.5rem; */
  /* font-weight: bold; */

}


/* YouTube video container */
.videoContainer iframe {
  width: 100%;
  height: 200px;

}

/* Contact Section */
.contactSection {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  gap: 10px;
  text-align: center;
}



.mailIcon > img{
/* width: 50vw; */
height: 50vh;
}


.contactInfo{
  flex: 1 1;
  box-shadow: var(--box-shadow);
   border-radius: 10px;
   padding: 40px;
}



/* Media Queries for Responsiveness */

/* Tablets and small laptops */
@media (max-width: 1024px) {
  .swiperText {
    padding: 0.5rem;
  }
  .serviceSection {
    flex-direction: column;
  }

  .serviceText,
  .serviceBoxes {
    width: 100%;
  }

  .serviceBox {
    width: 100%;
  }

  .fixedBackgroundSection,
  .fixedBackgroundImageSection {
    padding: 2rem 1rem;
  }

}

/* Mobile devices */
@media (max-width: 768px) {
  .swiperText {
    font-size: 1rem;
    padding: 0.5rem;
  }
  .customerImage {
    width: 120px;
  }

  .fixedBackgroundSection,
  .fixedBackgroundImageSection {
    padding: 2rem 1rem;
    font-size: 0.9rem;
  }

  .contactSection {
    flex-direction: column;
    align-items: center;

  }

  .contactInfo,
  .contactForm {
    width: 100%;
  
  }
 
  .mailIcon > img{
    width: 100%;
    
    }
}

/* Extra small devices */
@media (max-width: 480px) {
  .swiperText {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .customerImage {
    width: 100px;
  }

  .fixedBackgroundSection,
  .fixedBackgroundImageSection {
    padding: 1rem;
    font-size: 0.8rem;
  }

  .contactInfo,
  .contactForm {
    width: 100%;
  }
}