
.post-blog-page {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.blog-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;

}

.form-group input[type="text"],
.form-group input[type="url"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;

  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.form-group input:focus {
  outline: none;
 
}

.toggle-share {
  margin-bottom: 20px;
  text-align: center;
}

.toggle-share label {
  font-size: 16px;

}

.toggle-share input[type="checkbox"] {
  margin-right: 10px;
}

.submit-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.blog-list {
  margin-top: 30px;
}

.blog-list h2 {
  margin-bottom: 15px;
  font-size: 22px;

  text-align: center;
}

.blog-card {

  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.blog-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.blog-card h3 {
  margin-bottom: 10px;
  font-size: 20px;

}

.blog-card p {
  margin-bottom: 15px;
  font-size: 16px;

}

.blog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.blog-actions button {
  padding: 8px 15px;
  font-size: 14px;
  
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .post-blog-page {
    padding: 15px;
  }

  .blog-card {
    padding: 10px;
  }

  .submit-btn, .blog-actions button {
    font-size: 14px;
  }
}
