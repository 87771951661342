.promoSection {
  background-image: url('/public/assets/contact1.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  /* height: 50vh;  */
  padding: 5vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; 
}

.promoContent {
  z-index: 1; 
  position: relative;
 
  animation: fadeIn 1.5s ease-in-out; 
}

.promoContent h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.promoButton {
  padding: 12px 25px;

  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
}



.promoSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6); /* Darkened overlay for better text visibility */
  z-index: 0; 
  animation: fadeInOverlay 1s ease-in-out; /* Smooth fade-in overlay */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Keyframes for overlay fade-in */
@keyframes fadeInOverlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
