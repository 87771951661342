/* Main section styling */
.HomeTopSection {
  position: relative;
  width: 100%;
  height: 80vh; /* Full height for larger screens */
  overflow: hidden;
}

/* Swiper styling */
.mySwiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover for larger screens */
}

/* Overlay content (text and image) */
.overlay-content {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  z-index: 2; /* Ensures it sits above the swiper */
  text-align: left;
  color: white;
}

.overlay-content h2 {
  margin-bottom: 20px;
  color: #fff;
}

.typewriterText {
  font-weight: bold;
  color: #00e5ff; /* Adjust the color of the typewriter text */
}

/* Animated Image with shadow */
.animatedImageContainer {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  perspective: 1000px;
  position: relative;
}

.animatedImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mySwiper img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .HomeTopSection {
    height: 60vh;
  }

  .mySwiper {
    height: 60vh;
  }

  .mySwiper img {
    height: 50vh;
  }

  .overlay-content {
    padding: 20px;
    text-align: center; /* Center-align the text for smaller screens */
  }


  .overlay-content {
    top: 40%;
  }
}

@media (max-width: 480px) {
  .HomeTopSection {
    height: 50vh;
  }

  .mySwiper {
    height: 40vh;
  }

  .mySwiper img {
    height: 40vh;
  }

  .animatedImageContainer {
    margin-top: 15vh;
  }

  .typewriterText {
    font-size: 1rem; /* Adjust font size for readability */
  }

  .overlay-content {
    top: 30%;
  }
}


.animatedShadow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #e1e1e19e;
  transform: translate(-50%, -50%);
  animation: shadowAnimation 2s linear infinite alternate;
}

@keyframes shadowAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.2;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.8;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {  
  .overlay-content h2 {
    font-size: 1.5rem; /* Reduce font size on mobile */
  }

  .animatedImageContainer {
    width: 120px;
    height: 120px;
  }
}

