
/* Background Section */
.contact-bg-section {
  height: 80vh;
  

  position: relative;
  background-image: url('/public/assets/contactus.jpg'); 
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
  justify-content: center;
}
@media (max-width: 768px) {
  .contact-bg-section {
    height: 60vh; /* Adjusts banner height for smaller screens */
  }


}

@media (max-width: 480px) {
  .contact-bg-section {
    height: 50vh; /* Further adjustment for mobile screens */
  }

 
}


.contact-text {
  color: white;
  margin-left: 50px;

}



.contact-text p {
  font-size: 24px;
}

/* Contact Section */
.contact-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  gap: 10px;
}

.contact-info {
  flex: 1;
  /* max-width: 50%; */
  /* padding-right: 20px; */
}

.contact-box {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow:  var(--box-shadow);

}



.reverse {
  flex-direction: row-reverse;
}

.icon-wrapper {
  margin-right: 20px;
}

.reverse .icon-wrapper {
  margin-left: 20px;
}

.icon-circle {
 
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 24px;
}

.text-content h3 {

  margin-bottom: 10px;
}

.text-content p {
  font-size: 16px;
  margin-bottom: 10px;
}

.text-content a {

  text-decoration: none;
}

.text-content a:hover {
  text-decoration: underline;
}


/* Social and Location Section */
.social-location-section {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.social-box {
 
  display: flex;
justify-content: space-around;
text-align: start;
align-items: baseline;
  gap: 0 2em;
}

.social-icons a {
  margin-right: 10px;
  font-size: 24px;
  transition: color 0.3s;
}




/* Responsive Styles */
@media (max-width: 768px) {
  .social-location-section {
    flex-direction: column;
  }

  .social-box,
  .map-box {
    max-width: 100%;
    margin-bottom: 20px;
  }
}


