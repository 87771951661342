
.heading {
  font-size: 36px;
 
}

/*  */
/* Banner Section */
.banner {
  height: 80vh;
  position: relative;
  background: url('/public/assets/AboutUs.jpg') no-repeat center center;
  background-size: cover;
  background-position: center center;
  display: flex;
  max-height: 1000px;
  align-items: flex-end; 
  justify-content: center;
}



.banner-text {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 20px;
  z-index: 1;
  
  width: 100%;
  bottom: 0;
}



.banner-text p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner {
    height: 60vh; /* Adjusts banner height for smaller screens */
  }

 

  .banner-text p {
    font-size: 16px;
  }
}



/*  */
.story-section {
  padding: 50px 0 0 0;
  
}

.about-story-image {
  width: 100%;
  height:400px;
  object-fit: cover;
  border-radius: 10px;
}

.client-box {
  position: relative;
  bottom: 8em;
  right: -20em;
  width: 30%;
  
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.client-box p {
  font-size: 12px;
  font-weight: bold;
  
}

.story-subheading {
  font-size: 18px;
  font-weight: bold;

  margin-bottom: 10px;
}

.story-heading {

  font-size: 36px;
  font-weight: bold;
 
  margin-bottom: 20px;
}

.story-text {
  font-size: 18px;

  margin-bottom: 30px;
}
@media (max-width: 1000px) {
  .client-box {
   
  
    left: 90px ;
    width: 50%;
  }
}
/*  */
.milestone-section {
  gap: 20px;

}

.milestone-box {
 
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow:  var(--box-shadow);
  text-align: center;

}

.milestone-box p {
  font-size: 34px;
  font-weight: bold;


}

.milestone-box h2 {
  font-size: 18px;
  font-weight: bold;

  margin-bottom: 20px;
}

/*  */
.team-section {
  padding: 30px 0;

}

.team-heading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  
  margin-bottom: 20px;
}

.team-subheading {
  text-align: center;
  font-size: 18px;

  margin-bottom: 40px;
}

.team-member-card {
  border: 1px solid;
  text-align: center;
  border-radius: 9px;
}

.team-member-image {
  position: relative;
  overflow: hidden;
}

.team-member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0 ;
}

.team-member-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 

  text-align: center;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.team-member-image:hover .team-member-overlay {
  opacity: 1;
}

.team-member-card h2 {
  font-size: 18px;
  font-weight: bold;
 
  margin-top: 10px;
}

.team-member-card p {
  font-size: 14px;
  
}

/*  */
.what-we-do-section {
  padding: 30px 0;

}

.what-we-do-text {
  padding: 20px;
}

.what-we-do-text .subheading {
  font-size: 18px;

  margin-bottom: 10px;
}

.what-we-do-text .heading {
  font-size: 36px;
  font-weight: bold;

  margin-bottom: 20px;
}

.what-we-do-text p {
  font-size: 14px;
 
}

.what-we-do-card {
  margin-bottom: 20px;
  padding: 20px;
  border:  var(--border-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.what-we-do-card svg {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  color: var(--link-color);
}

.what-we-do-card h2 {
  font-size: 18px;
  font-weight: bold;
  /* color: #333; */
  margin-bottom: 10px;
}

.what-we-do-card p {
  font-size: 14px;
  /* color: #666; */
}

@media (max-width: 768px) {
 
  .what-we-do-section {
    padding: 50px 0;
  }

  .what-we-do-text {
    padding: 10px;
  }

  .what-we-do-card {
    margin-bottom: 10px;
    padding: 10px;
  }

  .what-we-do-card svg {
    width: 30px;
    height: 30px;

  }

  .what-we-do-card h2 {
    font-size: 16px;
  }

  .what-we-do-card p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 45vh; 
  }

  .what-we-do-section {
    padding: 20px 0;
  }

  .what-we-do-text {
    padding: 10px;
  }

  .what-we-do-card {
    margin-bottom: 10px;
    padding: 10px;
  }

  .what-we-do-card img {
    width: 20px;
    height: 20px;
  }

  .what-we-do-card h2 {
    font-size: 14px;
  }
}

/* 
*/
.synergy-section {
  padding: 30px 0 50px 0;

  text-align: center;
}

.synergy-text {
  margin: 0 auto;

}

.synergy-text .subheading {
  font-size: 18px;

  margin-bottom: 10px;
}

.synergy-text .heading {
  font-size: 36px;
  font-weight: bold;
 
  margin-bottom: 20px;
}

.submit-btn {

  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  width: max-content;
}




