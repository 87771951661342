.footer {
 
  padding: 2rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* .footer h1,.footer h2 ,footer h3,.footer h4,.footer h5,.footer h6,.footer p, .footer span, .footer li, .footer a{
  color: white;
} */
.footerBottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.footerBox {
  flex: 1;
  min-width: 200px;
  padding: 1rem;

 
}
.footerBox:last-child {
text-align: center;
 
}
.logo {
  width: 5rem;
  height: 5rem;
 border-radius: 50%;
  margin: auto;
}
.isoLogo {
  width: 40%;
  margin-top: 1rem;
}
.emailInput {
 
  padding: 0.5rem;
  margin: 0.5rem 0;
  width: 100%;
  border-radius: 10px;
}

.submit-btn {
  padding: 0.5rem 1rem;
  width: 100%;
  border: none;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  
}

.footerLinks {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerLinks li {
  margin-bottom: 0.5rem;
 
}

.footerLinks a {
  text-decoration: none;
 
 
}

.footerLinks a:hover {
  text-decoration: underline;
}

.contactInform {
  margin-top: 1rem;
}
.contactInform>div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.contactIcon {
  margin-right: 1rem;
}
/*  */
/* Footer.module.css */
.locationSection {
  display: flex;
  justify-content: space-around;
  margin: 5px 0;
}

.locationBox {
  position: relative;
  width: 45%;
  border-radius: 10px; 
  background: linear-gradient(45deg, #ff6b6b, #f7b733, #4facfe); 
  padding: 2px; 
}

.locationContent {

  border-radius: 12px; 
  padding: 5px 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.locationImage img {
 
  height: 3rem;
  border-radius: 50%;
}


/*  */
.socialsIcon{
  display: flex;
  justify-content: space-around;
  border-top:  var(--border-color);
  align-items: center;
}

.socialsIcon a {
  margin-right: 10px;
  font-size: 24px;
 
  transition: color 0.3s;
}




/* Responsive Styles */
@media (max-width: 1200px) {
 
  .footerImage {
 
    margin-bottom: 1rem;
  }

 

  .footerBox {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .footerBox {
    padding: 0.5rem;
  }

  .emailInput {
    width: 100%;
  }

  .submitButton {
    width: 100%;
  }

  .footerLinks a {
    font-size: 0.9rem;

  }

  .locationContent {
   
  flex-direction: column;
   text-align: center;
  }
}

@media (max-width: 480px) {
  .footerBox {
   
   text-align: center;
  }

  .footerBox {
    width: 100%;
    margin-bottom: 1rem;
  }

  .footerLinks a {
    font-size: 0.8rem;
  }
  .contactInform>div {
   
    justify-content: center;
  }
  .locationSection {
   flex-direction: column;
   gap: 2rem 0;
   width: 100%;
  }
  .locationBox {

flex-direction: column;
   width: 100%;
  }
}
