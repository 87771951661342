.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-popup-content {
 
  padding: 2rem;
  border-radius: 8px;
  width: 300px;
  box-shadow:  var(--box-shadow);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border:  var(--border-color);
  border-radius: 4px;
}

.btn-login {
  width: 100%;
  padding: 0.75rem;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}



