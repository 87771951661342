.about-us-section {
    padding: 50px 20px;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .about-us-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .about-us-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .about-us-content h2 {

    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .about-us-content p {
   
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-us-image {
    flex: 1;
    text-align: right;
  }
  
  .about-us-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .about-us-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-us-content {
      padding-right: 0;
    }
  
    .about-us-image {
      margin-top: 20px;
    }
  }
  