.dashboard {
  display: flex;

}

/* Sidebar Styles */
.sidebar {
  width: 15%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  transition: width 0.3s ease;
  position: fixed;
  overflow: scroll;
  height: 100vh;
  background: var(--bg-color);
  box-shadow: var(--box-shadow); 
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  background-color: #555;
}

.sidebar-item .icon {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.sidebar-item span {
  font-size: 0.9rem;
}

.main-content {
  margin-left: 15%;
  width: 85%;
  padding: 20px;
  transition: width 0.3s ease;

  
}

/* Responsive Sidebar for Mobile */
@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11111;
  }

  .main-content {
    width: 100%;
    margin-left: 0%;
    padding: 1rem;
    margin-bottom: 60px; /* To avoid overlap with bottom sidebar */
  }

  .sidebar-item {
    padding: 10px;
  }

  .sidebar-item .icon {
    font-size: 1.2rem;
  }
  .sidebar-item span {
   display: none;
  }
}
