
.map-box {
  flex: 1; 
  border-radius: 10px;
  overflow: hidden;
  box-shadow:  var(--box-shadow);
}

iframe {
  border: none;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .map-box {
    max-width: 100%;
    margin-bottom: 20px;
  }
}