/* General Navbar Styles */
.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between; /* Adjust spacing */
  align-items: center;  /* Align items vertically */
  padding: 0 2rem;
  width: 100vw;
  z-index: 999;
  gap: 20px;
}

.navbar-left {
  /* flex-grow: 1;  */
  /* Take up space on the left */
}

.navbar-logo img {
  width: 60px;
  border-radius: 50%;
}

.navbar-right {
  flex-grow: 1; /* Take up space on the right */
  display: flex;
  justify-content: center; /* Center the nav links */
  align-items: center;
}

.navbar-link {
  padding: 0.75rem 1rem;
  text-decoration: none;
  position: relative;
}

.navbar-link:hover,
.active-link {
  border-radius: 5px;
}

/* Dropdown Styles */
.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  z-index: 1;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item:hover,
.active-link {
  /* background-color: #555; */
}

/* Cloud Dropdown Styles */
.cloud-dropdown {
  cursor: pointer;
  position: relative;
}

.cloud-dropdown-content {
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
    padding: 0 1rem;
  }

  .navbar-right {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
  }

  .navbar-right.open {
    display: flex;
  }

  .navbar-link {
    padding: 1rem;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .dropdown-content {
    position: static;
    width: 100%;
  }

  .cloud-dropdown-content {
    position: static;
    width: 100%;
  }
}

/* Toggler */
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: var(--bg-color);
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-label .ball {
  background-color: #008bbaa4;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}
