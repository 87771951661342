.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
 
}

.home-title {

 
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.home-description {

  text-align: center;
  width: 60%;
  line-height: 1.6;
}
