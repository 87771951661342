/* General Page Styling */
.job-management-page {

  font-family: 'Arial', sans-serif;
}


.post-job-form {

  padding: 20px;
  border-radius: 8px;
  box-shadow:  var(--box-shadow); 
  margin-bottom: 20px;
  transition: transform 0.2s ease;
}

.post-job-form:hover {
  transform: translateY(-5px);
}

.post-job-form h2 {
  margin-bottom: 15px;
  font-size: 24px;
}

.post-job-form input,
.post-job-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border:  var(--border-color);
  border-radius: 5px;
  font-size: 16px;
}



.post-job-form button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  gap: 5px;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}



/* Jobs and Applicants Container */
.jobs-applicants-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .jobs-applicants-container {
    flex-direction: row;
  }
}

/* Jobs and Applicants Section */
.jobs-section{
  width: 100%;
}

@media (min-width: 768px) {
  .jobs-section {
    width: 48%;
  }
}






/* Popup Styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 500px;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; 
   height: 80vh;
  scrollbar-width: 1px;
  
}



.close-button {
  position: absolute;
    top: 1px;
    right: 1px;
    border: none;
    font-size: 15px;
}



.popup h3 {
  margin-bottom: 15px;
}

.popup form input,
.popup form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: var(--border-color);
  border-radius: 5px;
  font-size: 16px;
}

.popup form button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px 0;
}


/* Table Styles */
.courses-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.courses-table th, 
.courses-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  word-wrap: break-word;
}

.courses-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.courses-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.courses-table tr:hover {
  background-color: #ddd;
}

.courses-table td button {
  background-color: #28a745; 
  border: none;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.courses-table td button:hover {
  background-color: #218838;
}

.courses-table td button:nth-of-type(2) {
  background-color: #ffc107;
}

.courses-table td button:nth-of-type(2):hover {
  background-color: #e0a800;
}

.courses-table td button:nth-of-type(3) {
  background-color: #dc3545; 
}

.courses-table td button:nth-of-type(3):hover {
  background-color: #c82333;
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Stack table rows vertically */
  .courses-table, 
  .courses-table thead, 
  .courses-table tbody, 
  .courses-table th, 
  .courses-table td, 
  .courses-table tr {
    display: block;
    width: 100%;
  }

  /* Hide table headers (they will be added in the rows below) */
  .courses-table thead {
    display: none;
  }

  /* Each cell becomes its own row */
  .courses-table tr {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .courses-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
    
  }

  /* Add labels to each row */
  .courses-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    white-space: nowrap;
  }

  /* Button styles adjusted for small screens */
  .courses-table td button {
    padding: 8px;
    width: 100%;
 
  }

 
}
