.imageContainertop {
  position: relative;
  width: 100%;
  height: 80vh; /* Set relative height to viewport */
  overflow: hidden; /* Hide overflow of images */
  max-height: 1000px;
}

.textOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.textContent {
  position: absolute;
  bottom: 5%; /* Align text to the bottom */
  left: 0;
  right: 0;
  margin: auto;
}

.serviceName {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.serviceQuote {
  font-size: 2rem;
  color: white;
}

.serviceSwiper,
.swipperimg {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure image fits container while preserving aspect ratio */
}

@media (max-width: 768px) {
  .imageContainertop {
    height: 60vh; /* Adjust height for smaller screens */
  }

  .serviceQuote {
    font-size: 1.5rem; /* Reduce text size on smaller screens */
  }

  .serviceName {
    font-size: 3rem;
  }
}

@media (max-width: 480px) {
  .imageContainertop {
    height: 50vh; /* Further reduce height for small mobile screens */
  }

  .serviceSwiper,
  .swipperimg {
    object-fit: cover; /* On small devices, cover the area without cutting */
  }

  .serviceQuote {
    font-size: 1.2rem; /* Further reduce text size */
  }

  .serviceName {
    font-size: 2.5rem;
  }
}
