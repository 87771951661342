.user-roles-page{

  border-radius: 8px;
 
  margin: 0 auto;
}



.form-container {
  padding: 15px;
  border-radius: 8px;
  box-shadow:  var(--box-shadow); 
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
 
  margin-bottom: 5px;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;

 
}

.btn-submit {

  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}



.users-list {
  list-style: none;
  padding: 0;
}

.user-item {
 
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  border-bottom: 1px solid;
  box-shadow:  var(--box-shadow); 
}
.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-delete {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-delete:hover {
  background-color: darkred;
}


/*  */

/* css/UserRolesPage.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {

  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.modal table {
  width: 100%;
  margin-bottom: 20px;
}

.modal th, .modal td {
  text-align: left;
  padding: 8px;
}






