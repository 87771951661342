.notFoundContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.content {
  text-align: center;
}

.errorCode {
  font-size: 8rem;
  font-weight: bold;
  color: #ff6f61;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;

}

.description {
  font-size: 1.2rem;

  margin-bottom: 2rem;
}

.homeButton {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  background-color: #ff6f61;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.homeButton:hover {
  background-color: #e65a50;
}
