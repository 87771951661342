
/* Contact Form */
.contact-form {
  flex: 1;
  padding: 40px;
  border-radius: 10px;
 
  box-shadow:  var(--box-shadow);
}

.contact-form h2 {

  margin-bottom: 20px;
}
.contact-form img{
  width: 6rem;
  height: 6rem;
}
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row input,
.form-row textarea {
  width: 100%;
  padding: 10px;
 
  border-radius: 5px;


}

.form-row input {
  max-width: 100%;
}

textarea {
  resize: none;
}

.contact-form .submit-btn {
  width: 100%;
  padding: 12px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .social-location-section {
    flex-direction: column;
  }

  .social-box,
  .map-box {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }

  .contact-info {
    width: 100%;
    padding-right: 0;
  }
  .contact-form {
   width: 100%;
   
  }
  .form-row {
    flex-direction: column;
    gap: 10px;
  }
  .contact-form {
    
    padding: 10px;
   
  }
}