/* Blogs Page Styles */
.blogs-page .banner {
  text-align: left;
  padding: 0 0 50px 0;
  height: 80vh;
  position: relative;
  background: url(/public/assets/blogging.webp) no-repeat center center fixed;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
  justify-content: center;
}

.blogs-page .banner h1 {
  padding-top: 30vh;
  color: white;
}

.blogs-page .banner p {
  color: white;
}

.blogs-page .blogs-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.blogs-page .blog-card {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease-in-out;
}

.blogs-page .blog-card:hover {
  transform: translateY(-10px);
}

.blogs-page .blog-image {
  position: relative;
  width: 100%;
  height: auto; /* Allow height to adjust automatically */
}

.blogs-page .blog-image img {
  width: 100%;
  height: 40vh; /* Makes the height dynamic based on aspect ratio */
  object-fit: contain; /* Prevents cutting the image */
  transition: transform 0.4s ease-in-out;
}

.blogs-page .blog-card:hover .blog-image img {
  transform: scale(1.05); /* Slight zoom on hover */
}

.blogs-page .blog-info {
  padding: 20px;
  text-align: center;
}

.blogs-page .blog-icon {
  font-size: large;
  margin-bottom: 15px;
}

.blogs-page .blog-info h3 {
  margin-bottom: 10px;
}

.blogs-page .blog-info p {
  font-size: 1rem;
}

/* Overlay Text */
.blogs-page .overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* Blog Detail */
.blog-detail {
  text-align: center;
}
.blog-description {
  text-align: left;
}
.blog-detail .blog-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.blog-detail .blog-info {
  padding: 20px;
}

.blog-detail button {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 100vw;
  /* margin-top: 20px; */
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blogs-page .banner h1 {
    font-size: 2rem;
  }

  .blogs-page .banner p {
    font-size: 1rem;
  }

  .blogs-page .blog-info h3 {
    font-size: 1.5rem;
  }

  .blogs-page .blogs-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .blogs-page .banner h1 {
    font-size: 1.8rem;
  }

  .blogs-page .banner p {
    font-size: 0.9rem;
  }

  .blogs-page .blogs-container {
    padding: 10px;
  }

  .blogs-page .blog-info h3 {
    font-size: 1.3rem;
  }

  .blogs-page .banner {
    height: 50vh;
  }
}
