/* Define root variables for themes */
:root {
  --bg-color: #ffffff;
  --text-color: #1A1A1A;
  --secondary-text-color: #3D3D3D;
  --link-color: #008CBA;
  --button-bg: #008CBA;
  --button-color:#ffffff;
  --button-hover-bg: #005F7A;
  --navbar-bg: rgba(222, 222, 222, 0.952);
  --close-btn:rgb(232, 83, 83);
  --close-btn-hover:rgb(251, 1, 1);
  --box-shadow: 
  0 2px 4px rgba(0, 0, 0, 0.1),   
  0 6px 12px rgba(0, 0, 0, 0.15);
  --border-color:1px solid rgb(0, 0, 0);
  --bg-opposite: #000000; 
  --text-opposite-color: #ffffff;
  --secondary-text-oposite-color: #dddddd;
  --bg-theme-toggle: #ffffff85;
}

[data-theme='dark'] {
  --bg-color: #000000;
  --text-color: #ffffff;
  --secondary-text-color: #dddddd;
  --link-color: #008CBA;
  --button-bg: #008CBA;
  --button-hover-bg: #21b2da;
  --navbar-bg:  rgba(47, 47, 47, 0.952);
  --button-color:rgb(0, 0, 0);
  --box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 2px, rgba(255, 255, 255, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  --border-color:1px solid white;
  --bg-opposite: #ffffff;
  --text-opposite-color: #1A1A1A;
  --secondary-text-oposite-color:#3D3D3D;
  --bg-theme-toggle: #00000085;
}


body, .popup{
  background-color: var(--bg-color);
  color: var(--text-color);
 

}
body{
  overflow-x: hidden;
  
}
.main{
  max-width: 1900px;
  margin: auto;
}
/* section{
  overflow-x: hidden;
} */
footer,.locationContent{
  background: var(--bg-opposite);
  color: var(--text-opposite-color);
}
footer h3{

  color: var(--text-opposite-color);
}
footer a,footer h4{
  text-decoration: none;
  color: var(--secondary-text-oposite-color);
}
.popup{
  box-shadow: var(--box-shadow);
}
.navbar{
  background-color: var(--navbar-bg);
  color: var(--text-color);
  box-shadow: var(--box-shadow);
}
.navbar-link {
  color:var(--text-color);
}
.active ,.dropdown-item .active{
  background-color: var(--bg-color); 
}
.navbar-link:hover,
.active-link {
  color: var(--secondary-text-color);
  background-color: var(--bg-color);
}
.dropdown-content {
  background-color: var(--navbar-bg);
  box-shadow:var(--box-shadow);
}
.dropdown-item {
  color: var(--text-color);
}
.dropdown-item:hover,
.active-link {
  background-color: var(--bg-color);
}

.cloud-dropdown-content {
  background-color: var(--navbar-bg);
  box-shadow: var(--box-shadow);
}
.mobile-menu-toggle, .theme-toggle-btn {
  color: var(--text-color);
  background-color: var(--navbar-bg);
}
.mobile-menu-toggle:hover,.theme-toggle-btn:hover {
  color: var(--secondary-text-color);
  background-color: var(--bg-color);
}
@media (max-width: 768px) {
  .navbar-right {
  
    background-color: var(--navbar-bg);
   
  }
  .dropdown-content {
   
    background-color: var(--bg-color);
  }

  .cloud-dropdown-content {
 
    background-color: var(--bg-color);
  }
}
::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
h1 {
  /* font-size: clamp(2rem, 4vw + 1rem, 3.5rem);  */
  
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  padding:1rem 0;
  font-family: 'Times New Roman', Times, serif;
}

h2 {
  /* font-size: clamp(1.5rem, 3vw + 0.5rem, 2.5rem);  */
  font-family: 'Times New Roman', Times, serif;
}

.header, .sub-header{
  text-align: center;
}
h1, h2, h3 {
  color: var(--text-color);
}

h4, h5,a {
  color: var(--secondary-text-color);
}
.typewriterText{
  color: var(--link-color);
}

.error-message{
  color: red;
  text-align: center;
}
.info{
  color: var(--link-color);
  text-align: center;
}
button, .submit-btn,.icon-circle {
  background-color: var(--button-bg);
  color: var(--button-color);
}

button:hover, .submit-btn:hover {
  background-color: var(--button-hover-bg);
}
.close-button{

  background-color: var(--close-btn);
}
.close-button:hover{
  background-color: var(--close-btn-hover);
}

span.typewriterText {
  
   font-weight: bold;
 }
 .stat-number {
   color:var(--link-color); 
  
 }
/* animation */
[data-aos="tilt"] {
  transform: skewX(45deg);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: skewX(0);
    opacity: 1;
  }
}


[data-aos="round"] {
  transform: rotate(360deg);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: rotate(0);
    opacity: 1;
  }
}