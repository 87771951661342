/* Customer Stories Section */
.customer-stories {
 text-align: center;
  }
  
  .hero-image {
    width: 100%;
    overflow: hidden;
    margin:0 0 20px 0 ;
  }
  
  .hero-img {
    width: 100%;
    height: 80vh;
    object-fit: cover; 
    display: block;

  }
  
  .customer-stories h1 {

    margin-bottom: 40px;
  }
  
  .stories-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 20px;
  }
  
  .story-card {
  
    padding: 5px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    width: 100%;
    
   text-overflow: clip;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
  }
  
  .story-card:hover {
   transform: translateY(-10px);
   box-shadow:  var(--box-shadow);
  }
  
  .story-image {

    width: 5em;
    height: 5em;
    object-fit: cover;
   
    margin: auto;
    border-radius:50%;
  }
  

  
  .story-card .company {
    font-weight: bold;

  }
  

  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .stories-grid {
      grid-template-columns: repeat(3, 1fr); 
    }
  }
  @media (max-width: 768px) {
    .hero-img {
      height: 60vh;
    }
    .stories-grid {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  @media (max-width: 490px) {
    .hero-img {
      height: 40vh;
    }
    .stories-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  