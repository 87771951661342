

.fixed-background-section {
  position: relative;
  background: url('/public/assets/careersbanner.webp') no-repeat center center;
  background-attachment: fixed;
  background-size: cover; 
  height: 80vh; 
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses-page > .fixed-background-section {
  position: relative;
  background: url('/public/assets/online_learning.jpg') no-repeat center center;
  background-attachment: fixed;
  background-size: cover; 
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses-page .fixed-text {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 500px;
  color: white;
  padding: 20px;
}

/* Common Styles */
.fixed-text h1 {
  color: white;
  margin-bottom: 10px;
}

.fixed-text p {
  font-size: 24px;
}

/* Responsive Fixes */
@media (max-width: 768px) {
  .fixed-background-section {
    background-position: center center;
  }

  .courses-page .fixed-background-section {
    height: 60vh;
    background-position: center center;
  }
}

@media (max-width: 480px) {
  .fixed-background-section {
   
    background-position: center center;
  }

  .courses-page .fixed-background-section {
   
    background-position: center center;
  }

  .fixed-text p {
    font-size: 18px; 
  }
}



.openings-section {
  padding: 60px 20px;
  text-align: center;
}

.section-heading {
  font-size: 36px;
  margin-bottom: 20px;
}

.section-description {
  font-size: 18px;
  margin-bottom: 40px;
}

.job-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.job-card {
  padding: 20px;
  border-radius: 10px;
  flex: 1 1 calc(33% - 20px);
  box-shadow:  var(--box-shadow);
  min-height: 150px; /* Added minimum height */
}

.job-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.job-details {
  margin-top: 20px;
}

.job-details p {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.job-details p svg {
  margin-right: 8px;
}

.hover-button {
  position: absolute;
  bottom: 25px;
  left: 70%;
}

.hover-button > button {
  padding: 10px 20px;
}



.popup {
    padding: 20px;
    border-radius: 10px;
    width: 400px;
     padding: 20px;
     border-radius: 10px;
     position: relative;
     max-width: 500px;
     width: 100%;
    
}
.popup-job-description{
  overflow-y: auto;
  max-height: 70vh;
}
.popup h3 {
  margin-bottom: 20px;
}

.popup form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup form div {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.popup button {

  padding: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
  border: none;
  font-size: 15px;

}

.resume-button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
}

.no-job-section {
  position: relative;
  background: url('/public/assets/Careersmid.webp') no-repeat center center fixed;
  background-size: cover;
  height: 40vh;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  padding-top: 5vh;
 
}
.no-job-section >h2,.no-job-section>p{
  color: white;
}
.resume-button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
}



@media (max-width: 768px) {
  .job-cards {
    flex-direction: column;
  }
}
.why-section {
  padding: 60px 20px;
  text-align: center;
 
}

.why-section h2 {
 
  margin-bottom: 20px;
  text-transform: uppercase;

}

.why-section p {
  font-size: 1.2rem;

  margin-bottom: 40px;
}

.why-cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.why-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  border-radius: 10px;
  box-shadow:  var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.why-card:hover {
  transform: translateY(-10px); /* Slight hover lift effect */
  box-shadow:  var(--box-shadow); /* Enhanced shadow on hover */
}

.why-card.reverse {
  flex-direction: row-reverse; /* Reverses the layout for alternating styles */
}

.why-text {
  flex: 1;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.why-text h3 {
  font-size: 1.8rem;

  margin-bottom: 10px;
}

.why-text p {
  font-size: 1rem;

  line-height: 1.6;
}

.why-image {
  flex: 1;
  animation: scaleIn 1.5s ease;
}

.why-image img {
  max-width: 30rem;
  border-radius: 10px;
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .why-cards {
    gap: 20px;
  }

  .why-card {
    flex-direction: column;
  }

  .why-card.reverse {
    flex-direction: column;
  }
}
