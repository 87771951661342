/* Default styling for larger screens */
.blog-detail-page {
  padding-top: 60px;
}

.blog-description img {
  max-width: 100%; /* Ensures images never overflow the container */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 20px auto; /* Center the image */
  border-radius: 8px; /* Optional, for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional, for a slight shadow */
}

/* Media queries for responsive design */

/* Large screens */
@media (min-width: 1024px) {
  .blog-description img {
    width: 70%; /* Reduce width for large screens */
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .blog-description img {
    width: 80%; /* Adjust to look good on tablets */
  }
}

/* Small screens (mobile phones) */
@media (max-width: 767px) {
  .blog-description img {
    width: 90%; /* Slightly smaller for mobile screens */
  }
}
