/* Details Section */
.detailsSection {
  padding: 50px 10px;
  text-align: center;
}

.detailsSection h1.header {
  
  margin-bottom: 30px;
  position: relative;
}

.detailsSection h1.header::after {
  content: '';
  width: 90px;
  height: 4px;
  background-color: #008CBA;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

.detailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  /* max-width: 1200px; */
  margin: 0 auto;
  flex-wrap: wrap;
}

.singleImage {
  flex: 1;
  /* max-width: 500px; */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: var(--box-shadow);

}

.singleImage img {
  width: 100%;
  transition: transform 0.4s ease-in-out;
}

.singleImage:hover img {
  transform: scale(1.05);
}

.explanationBox {
  flex: 1;
  /* max-width: 600px; */
  padding: 20px;
  text-align: left;
  /* border-radius: 8px; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); */
}

.explanationBox ul {
  list-style-type: none;
  padding: 0;
}

.explanationBox ul li {
  /* font-size: 1rem; */
  /* line-height: 1.8; */
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
 

}

.explanationBox ul li::before {
  content: '⇨';
  position: absolute;
  left: -10px;
  top: -10px;
  color: #008CBA;
  font-size: 2rem;
}