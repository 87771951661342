.blog-highlight-section {
  padding: 5rem 2rem;

}

.blog-highlight-container {
  display: flex;
  align-items: top;
  justify-content: space-between;

  margin: 0 auto;
  gap: 30px;
  flex-wrap: wrap;
  max-height: min-content;
}

.blog-highlight-content {
  flex: 1;

}

.blog-highlight-content h2 {


  margin-bottom: 20px;
}

.blog-highlight-content p {


  margin-bottom: 15px;

}





.blog-highlight-image {
  flex: 1;
  /* max-width: 600px; */
 
}

.blog-highlight-image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .blog-highlight-container {
    flex-direction: column;
  }
}